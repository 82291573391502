import React from 'react';
import { HorizontalListingItem } from '@components/horizontal-listing/horizontal-listing-item';
import { ListingProps } from '@models/Listing';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    margin: `${theme.spacing(5)}px 0`,
  },
}));

export const HorizontalListing: React.FC<ListingProps> = ({ items }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {items.map(item => (
        <HorizontalListingItem {...item} key={item.directUrl} />
      ))}
    </div>
  );
};
