import React from 'react';
import { FullScreenLayout } from '@components/layouts';
import { BreadcrumbContainer } from '@components/breadcrumb-container/breadcrumb-container';
import { HorizontalListing } from '@components/horizontal-listing/horizontal-listing';
import { graphql, useStaticQuery } from 'gatsby';
import { Container } from '@material-ui/core';
import { faMicroscope } from '@fortawesome/free-solid-svg-icons/faMicroscope';
import { transformToListingProps } from '../utils/transform-to-listing-props';
import { useAllExperiments } from '../queries/use-all-experiments';
import { useSetHeaderContext } from '../hooks/useSetHeaderContext';

const Experiments: React.FC = () => {
  const data = useAllExperiments();
  const image = useStaticQuery(graphql`
    query ExperimentsBannerQuery {
      imageSharp(resize: { originalName: { eq: "experiments-backdrop.jpg" } }) {
        id
        gatsbyImageData(transformOptions: { cropFocus: CENTER, fit: COVER })
      }
    }
  `);

  useSetHeaderContext({
    image: image.imageSharp.gatsbyImageData,
    title: 'Eksperymenty',
    subtitle: 'próbki kodu i działające projekty demonstracyjne',
    height: 300,
  });

  return (
    <FullScreenLayout>
      <Container maxWidth="md">
        <BreadcrumbContainer
          links={[
            { text: 'Eksperymenty', link: '/experiments', icon: faMicroscope },
          ]}
        />
        <HorizontalListing items={transformToListingProps(data)} />
      </Container>
    </FullScreenLayout>
  );
};

export default Experiments;
