import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { ListingItemProps } from '@models/Listing';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Button, Grid, Link, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(4),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  title: {
    fontWeight: 400,
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    borderRadius: '100%',
    display: 'inline-block',
    maxWidth: '100%',
    height: 'auto',
    maxHeight: '200px',
    margin: `${theme.spacing(4)}px 0`,
  },
  excerpt: {
    textAlign: 'justify',
    margin: `${theme.spacing(2)}px 0`,
  },
}));

export const HorizontalListingItem: React.FC<ListingItemProps> = ({
  author,
  thumbnail,
  directUrl,
  publishedAt,
  excerpt,
  title,
}) => {
  const absoluteUrl = `/${directUrl}`;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3} className={classes.imageContainer}>
          <Link to={absoluteUrl} component={GatsbyLink}>
            {thumbnail && (
              <GatsbyImage
                alt={title}
                image={thumbnail}
                className={classes.image}
              />
            )}
          </Link>
        </Grid>
        <Grid item xs={12} md={9}>
          <Link
            to={absoluteUrl}
            component={GatsbyLink}
            variant="h4"
            className={classes.title}
          >
            {title}
          </Link>
          <Typography variant="subtitle2" color="textSecondary">
            opublikowano {publishedAt} r.
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {author}
          </Typography>
          <Typography variant="body1" className={classes.excerpt}>
            {excerpt}
          </Typography>
          <Button
            to={absoluteUrl}
            component={GatsbyLink}
            className="text-muted ml-1"
            variant="outlined"
            color="primary"
          >
            czytaj dalej
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
